import { ClickableTile } from "carbon-components-react";
import ky from "ky";
import { useEffect, useState } from "react";
import styles from "./App.module.scss";

type WikiItem = {
  name: string;
  url: string;
};

type WikiGroups = Record<string, Array<WikiItem>>;

export default function App() {
  const [groups, setGroups] = useState<WikiGroups | null>(null);

  useEffect(() => {
    async function run() {
      const ret: WikiGroups = {};
      const config: any = await ky(
        `https://tidb.wiki.pingcap.net/config.json?t=${Date.now()}`
      ).json();
      const navItems = config.NavItems;
      let currentGroup: string | null = null;
      if (navItems) {
        for (let nav of navItems) {
          if (nav.text !== "All Wiki") {
            continue;
          }
          for (const item of nav.children) {
            if (item.type === "divider") {
              currentGroup = item.text;
              ret[currentGroup!] = [];
            } else if (item.type === "link" && currentGroup) {
              ret[currentGroup].push({
                name: item.text,
                url: item.href,
              });
            }
          }
        }
      }
      setGroups(ret);
    }
    run();
  }, []);

  return (
    <div className={styles.container}>
      <div className="bx--grid bx--grid--condensed" style={{ width: "100%" }}>
        <div className="bx--row">
          <div className="bx--col-sm-4">
            <h2>PingCAP Wiki</h2>
          </div>
        </div>
        {Boolean(groups) &&
          Object.keys(groups!).map((groupKey) => {
            const items = groups![groupKey];
            return (
              <>
                <div className="bx--row">
                  <div className="bx--col-sm-4">
                    <h4>{groupKey}</h4>
                  </div>
                </div>
                <div className="bx--row">
                  {items.map((item) => {
                    return (
                      <div className="bx--col-lg-3 bx--col-md-4 bx--col-sm-4">
                        <ClickableTile className={styles.tile} href={item.url}>
                          {item.name}
                        </ClickableTile>
                      </div>
                    );
                  })}
                </div>
              </>
            );
          })}
      </div>
    </div>
  );
}
